import camelize from 'camelize-ts'
import { uniq } from 'lodash'

import { BMP, PharmaProduct, isMedicationItem } from 'types/medicationPlan'

import { digestString } from './digest'

export const assignPharmaProductInfo = async (bmp: BMP): Promise<void> => {
  const pharmaProductsByPzn = new Map<PharmaProduct['pzn'], PharmaProduct>()

  const medicationEntriesMissingInfo = bmp.sections
    .flatMap((section) => section.items)
    .filter(isMedicationItem)
    .filter((item) => item.pzn)

  const prefixes = await Promise.all(
    uniq(medicationEntriesMissingInfo.map((item) => item.pzn!)).map(getDigestPrefix),
  )

  await Promise.all(
    uniq(prefixes).map(async (prefix) => {
      const body = await fetchPharmaProductsByPrefix(prefix)

      body.data.forEach((pharmaProduct) => {
        pharmaProductsByPzn.set(pharmaProduct.pzn, pharmaProduct)
      })
    }),
  )

  medicationEntriesMissingInfo.forEach((item) => {
    const pharmaProduct = pharmaProductsByPzn.get(item.pzn!)
    if (!pharmaProduct) {
      console.error(`Pharma product ${item.pzn} not found`)
      return // Skip this item
    }
    // TODO: INEX-227 Find the active ingredients. This is not yet implemented in the backend.
    // if (item.activeIngredients.length === 0) {
    //   item.activeIngredients = pharmaProduct.activeIngredients
    // }
    item.commercialName ||= pharmaProduct.productName
    item.doseForm ||= pharmaProduct.doseForm
  })
}

async function getDigestPrefix(pzn: string) {
  return (await digestString(pzn)).slice(0, 3)
}

async function fetchPharmaProductsByPrefix(prefix: string): Promise<{ data: PharmaProduct[] }> {
  const response = await fetch(`/api/frontend/ifa/pharma_products?pzn_prefix=${prefix}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.status !== 200) {
    console.error(`Failed to fetch pharma products for prefix ${prefix}`, response)
    return { data: [] }
  }
  const body = (await response.json()) as {
    data: {
      productName: string
      pzn: string
      dose_form: string
      validity_article_data_on: string // ISO 8601 date
    }[]
  }
  return camelize(body)
}
