import _ from 'lodash'

import { BMP, Section, MedicationItem, RecipeItem, FreetextItem } from '../../types/medicationPlan'

export default function createBMPStructure(xmlDoc: Document | null): BMP {
  if (!xmlDoc) throw new Error('No xmlDoc provided')

  const patient = xmlDoc.getElementsByTagName('P')[0]
  const printoutInformation = xmlDoc.getElementsByTagName('A')[0]

  if (!patient) throw new Error('No patient information found')

  const bmp: BMP = {
    patient: {
      firstName: patient.getAttribute('g') || '',
      lastName: patient.getAttribute('f') || '',
      birthDate: patient.getAttribute('b') || '',
    },
    printer: {
      name: printoutInformation.getAttribute('n') || '',
    },
    sections: [],
  }

  const sections = xmlDoc.getElementsByTagName('S')

  _.forEach(sections, (section) => {
    const sectionObj: Section = {
      title: section.getAttribute('t') || null,
      items: _.map(section.children, (item) => {
        switch (item.tagName) {
          case 'M':
            return extractMedicationDetails(item)
          case 'R':
            return extractRecipeDetails(item)
          case 'X':
            return extractFreeTextDetails(item)
          default:
            console.warn('section item not recognized', item.tagName)
        }
      }).filter(Boolean) as (MedicationItem | RecipeItem | FreetextItem)[],
    }

    bmp.sections = _.concat(bmp.sections, sectionObj)
  })

  return bmp
}

function extractMedicationDetails(medElement: Element): MedicationItem {
  const attrP = medElement.getAttribute('p')
  return {
    type: 'medication',
    activeIngredients: getActiveIngredientAttributes(medElement),
    boundAdditionalLine: medElement.getAttribute('x') || '',
    commercialName: medElement.getAttribute('a') || '',
    // Add back leading 0s. See Feldcode 4.0 in chapter 7.3.1
    pzn: attrP ? attrP.padStart(8, '0') : '',
    dosage: {
      // We have either a free text or the time-specific dosages
      morning: medElement.getAttribute('m') || '0',
      noon: medElement.getAttribute('d') || '0',
      evening: medElement.getAttribute('v') || '0',
      night: medElement.getAttribute('h') || '0',
      freeText: medElement.getAttribute('t') || '',
    },
    dosageUnitCode: medElement.getAttribute('du') || '',
    dosageUnitFreeText: medElement.getAttribute('dud') || '',
    instructions: medElement.getAttribute('i') || '',
    reason: medElement.getAttribute('r') || '',
    doseForm: medElement.getAttribute('f') || '',
    doseFormFreeText: medElement.getAttribute('fd') || '',
  }
}

function extractRecipeDetails(medElement: Element): RecipeItem {
  return {
    type: 'recipe',
    text: medElement.getAttribute('t') || '',
    boundAdditionalLine: medElement.getAttribute('x') || '',
  }
}

function extractFreeTextDetails(medElement: Element): FreetextItem {
  return {
    type: 'free_text',
    text: medElement.getAttribute('t') || '',
  }
}

function getActiveIngredientAttributes(medElement: Element) {
  const activeIngredients = medElement.getElementsByTagName('W')

  return _.map(activeIngredients, (activeIngredient) => {
    return {
      name: activeIngredient.getAttribute('w') || '',
      strength: activeIngredient.getAttribute('s') || '',
    }
  })
}
