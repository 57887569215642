import { Controller } from '@hotwired/stimulus'

import {
  loadDeviceKeyPairForPassword,
  generateAndUploadDeviceKeyPairForPassword,
} from '../encryption/encryption'

/**
 * LoginController
 */
export default class LoginController extends Controller<HTMLFormElement> {
  static targets = ['password']

  async onSubmitEnd(event: CustomEvent) {
    if (event.detail.success) {
      event.preventDefault()
      const password = this.passwordTarget.value
      const deviceKey = await loadDeviceKeyPairForPassword(password)
      if (!deviceKey) {
        await generateAndUploadDeviceKeyPairForPassword(password)
      }
    }
  }

  connect() {
    window.sessionStorage.removeItem('deviceKey')
    window.sessionStorage.removeItem('reset_password_token')
  }

  declare passwordTarget: HTMLInputElement
}
