import { Controller } from '@hotwired/stimulus'

import { assignPharmaProductInfo } from 'services/medicationPlan/assignPharmaProductInfo'

import createBMPStructure from '../../services/medicationPlan/createBMPStructure'
import {
  generateMedicationPlanHTML,
  warningMessage,
  errorMessage,
} from '../../services/medicationPlan/generateHtml'
import parseMedicationPlan from '../../services/medicationPlan/parseMedicationPlan'

interface BMPLoadedEvent extends Event {
  detail: string
}

/**
 * MedicationPlanShow renders the medication plan as HTML
 */
// Connects to data-controller="medication-plan-show"
export default class extends Controller<HTMLDivElement> {
  static targets = ['medicationPlanContainer']
  static values = { bmp: String }

  connect() {
    if (this.hasBmpValue) {
      this.renderHtml(this.bmpValue)
    }
  }

  renderBmpObject(event: BMPLoadedEvent) {
    this.renderHtml(event.detail)
  }

  async renderHtml(bmp: string) {
    try {
      const parsedMedicationPlan = parseMedicationPlan(bmp)
      const bmpDocument = createBMPStructure(parsedMedicationPlan?.document)
      await assignPharmaProductInfo(bmpDocument)
      const { patientDataContainer, medicationEntriesPanels } =
        generateMedicationPlanHTML(bmpDocument)

      if (parsedMedicationPlan.validation.warnings.length > 0) {
        this.element.prepend(warningMessage())
      }

      // Render generated HTML of the medication plan
      this.medicationPlanContainerTarget.replaceChildren(
        patientDataContainer,
        medicationEntriesPanels,
      )
    } catch (e) {
      this.element.replaceChildren(errorMessage())
      console.error(e)
    }
  }

  declare hasBmpValue: boolean
  declare bmpValue: string
  declare medicationPlanContainerTarget: HTMLDivElement
}
