import { intl, t } from '../../translations/i18n'

export const dosageUnitMappings = (dosageUnitCode: string): string => {
  const translationKey = 'medication_plan.dosage_unit_codes.' + dosageUnitCode

  if (dosageUnitCode && intl.messages[translationKey]) {
    return t(translationKey)
  } else {
    return dosageUnitCode
  }
}
