import { Controller } from '@hotwired/stimulus'

import { CSRFToken } from '../encryption/encryption'

// This is the number of retries to check if the device key is loaded
// Every retry waits for one more second for device key to be loaded
// After this number of retries, the user is logged out
// For the mobile devices we had to increase the number, because
// creation of the device key takes longer there
const RETRIES_COUNT = 60

// Connects to data-controller="encryption-unlocking"
export default class extends Controller {
  static values = { redirectPath: String }

  connect() {
    this.checkDeviceKey(RETRIES_COUNT)
  }

  checkDeviceKey(retry: number) {
    if (retry > 0) {
      const sessionKeyLoaded = Boolean(window.sessionStorage.getItem('deviceKey'))
      if (sessionKeyLoaded) {
        window.location.assign(this.redirectPathValue)
      } else {
        setTimeout(() => {
          this.checkDeviceKey(retry - 1)
        }, 1000)
      }
    } else {
      this.logoutUser()
    }
  }

  logoutUser() {
    return fetch('/patient_users/sign_out?reason=unlocking_error', {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': CSRFToken(),
      },
    }).then((response) => {
      window.location.assign(response.url)
    })
  }

  declare redirectPathValue: string
}
